import ApiService from "../api_service";
import * as statusMapper from "@/service/error_request_mapper.js";

export class ProductPriceEstablishmentService extends ApiService {
  constructor(api) {
    super(api);
  }


  /** Récupération de l'ensemble des tarifs pour les établisssment */
  async getAll() {
    return this.api
      .getAll()
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération de l'ensemble des tarif de produits pour un estab */
  async getAllProductPricesEstablishment(establishmentId) {
    return this.api
      .getAllProductPricesEstablishment(establishmentId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(establishmentId);
        converter.convert(error);
      });
  }

  /** Récupération d'un tarif de produits pour un estab */
  async getById(productPriceId) {
    return this.api
      .getById(productPriceId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(productPriceId);
        converter.convert(error);
      });
  }


  /** Création d'un tarif de produit pour un établissement */
  async create(datas) {
    return this.api
      .create(datas)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(datas);
        converter.add409AlreadyExists(datas);
        converter.convert(error);
    });
  }

  /** Mise à jour d'un tarif de produit pour un établissement */
  async update(datas) {
    return this.api
      .update(datas)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(datas.id);
      converter.convert(error);
    });
  }

  /** Suppression d'un tarif de produit pour un établissement */
  async delete(productPriceId) {
    return this.api
      .deleteProductPriceEstablishment(productPriceId)
      .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(productPriceId);
      converter.convert(error);
    });
  }

}
