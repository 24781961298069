<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Type de tarif" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Modifier un type de tarif</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="label"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

             <!-- le nombre de montant -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nombre de montant</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field 
                  dense 
                  v-model="nbAmount"
                  type="number"
                  min="0"
                  step="1"
                  :rules="[rules.positive]"
                  @blur="onQuitNbAmount"
                  ></v-text-field>
                </v-col>
              </v-row>              
            </v-card-text>
            
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- Fenetre modale pour l'interdiction de modification du nombre de montant -->
    <StandardDialogInfo
    title="Modification impossible"
    label="Vous ne pouvez pas diminuer le nombre de montant car ce type de tarif est utilisé."
    :labelHtml="false"
    textButton="OK"
    :visible.sync="showStandardDialog">
    </StandardDialogInfo>


    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import StandardDialogInfo from "@/components/ui/StandardDialogInfo.vue"

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { PriceTypesService } from "@/service/conf/price_types_service.js";
import { ProductPriceEstablishmentService } from "@/service/conf/product_price_establishment_service.js";
import { ProductPriceGroupeService } from "@/service/conf/product_price_groupe_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditPriceTypes",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    StandardDialogInfo,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de la vue */
      service: null,
      servicePriceEstablishment: null,
      servicePriceGroupe: null,

      /** l'identifiant à éditer */
      entityId: null,

      /** le nom */
      label: null,
      /** le nom digital */
      digitalName: null,
      /** le nombre de montant */
      nbAmount: null,

      /** Les tarifs par établissement */
      pricesEstablishment: [],
      pricesGroupe: [],

      /** Les règles de saisie */
      rules: {
        positive: (value) => this.pricePositiveRules(value),
      },

      /** la source */
      source: null,

      // Affiche la fenêtre d'info
      showStandardDialog: false,

    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération du tarif
        let entity = await this.service.getById(this.entityId);
        // Récupération de l'ensemble des tarifs établissement
        this.pricesEstablishment = await this.servicePriceEstablishment.getAll();
        // Récupération de l'ensemble des tarifs groupe
        this.pricesGroupe = await this.servicePriceGroupe.getAll();

        this.source = JSON.parse(JSON.stringify(entity));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init() {
      this.label = this.source.label;
      this.digitalName = this.source.digitalName;
      this.nbAmount = this.source.nbAmount;     },

    async save() {
      try {
        let element = JSON.parse(JSON.stringify(this.source));
        element.label = this.label;
        element.digitalName = this.digitalName;
        element.nbAmount = this.nbAmount;

        await this.service.update(element);

        this.source = JSON.parse(JSON.stringify(element));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Evènement lors du quit du champ du nombre de montant */
    onQuitNbAmount () {
      // Affecte à la variable uniquement la partie entière au cas où une partie décimale aurait été saisie.
      this.nbAmount = Math.floor(this.nbAmount);
    },

    /** Restriction pour les nombres négatifs */
    pricePositiveRules(value) {
      if (value) {
        if(parseFloat(value) < 0 ) {
          return "pas de valeur négative";
        }
      }
      return true;
    },

    /** Contrôle si type utilisé */
    typeIsUsed() {
      let foundPriceEstab = this.pricesEstablishment.find((p) => p.priceType == this.entityId);
      let foundPriceGroupe = this.pricesGroupe.find((p) => p.priceType == this.entityId);

      /** Rien trouvé, le type n'est pas use */
      if (!foundPriceEstab && !foundPriceGroupe) {
        return false;
      } else {
        this.showStandardDialog = true;
        return true;
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    completed() {
      if (!this.label) return false;
      if (!this.digitalName) return false;
      if (!this.nbAmount) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      // Contrôle si le nombre de montant n'as pas diminué
      if (this.source.nbAmount > this.nbAmount) {
        if (this.typeIsUsed()) return false;
      }

      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      if (!areTheSame(this.source.digitalName, trimString(this.digitalName))) {
        changed = true;
      }
      if (this.source.nbAmount != this.nbAmount) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {

    // Instanciation des services
    this.service = new PriceTypesService(this.$api.getPriceTypesApi());
    this.servicePriceEstablishment = new ProductPriceEstablishmentService(this.$api.getProductPricesEstablishment());
    this.servicePriceGroupe = new ProductPriceGroupeService(this.$api.getProductPricesGroupe());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>